.popular-container {
    padding: 30px;
}

.category-section {
    text-align: center;
}

.category-section h1 {
    margin-bottom: 20px;
}

.products {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    gap: 80px; /* Add spacing between products */
}

.product {
    text-align: center;
    position: relative;
    max-width: 220px; /* Set a fixed max-width for consistency */
    margin-bottom: 20px; /* Add some space at the bottom */
    height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Ensure content is centered */
    align-items: center;
}

.product img {
    width: 100%; /* Set a fixed width */
    height: 280px; /* Set a fixed height */
    object-fit: contain; /* Ensure the whole image fits within the container */
    /* border: 0px solid #ddd; 
    padding: 5px;  */
    background-color: #f9f9f9; /* Optional: Set a background color for the image container */
}

.product h2, .product, .rating, .product .description {
    margin-top: 10px;
}

.product h2 {
    margin-top: 5px;
    font-size: 20px;
    color: #000000;
}

.product h2 a {
    color: inherit;
    text-decoration: none;
}

.product h2 a:hover {
    text-decoration: underline;
}

.rating {
    margin-top: 10px;
    font-size: 20px;
    color: #eee016; /* Customize rating color */
}

.rating span {
    margin-left: 5px;
}

.description {
    margin-top: 10px;
    font-size: 14px;
    color: #666666; /* Set a color that contrasts well with the background */
    text-align: center;
}

.header {
    text-align: center;
    margin-bottom: 40px;
}