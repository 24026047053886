.navbar {
  background: linear-gradient(90deg, rgb(28, 28, 27) 0%, rgb(26, 23, 23) 100%);
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  font-size: 1.2rem;
  position: sticky;
  top: 0;
  z-index: 999;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.navbar-logo {
  color: #fff;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
}

.menu-icon {
  color: #fff;
  font-size: 1.8rem;
  cursor: pointer;
}

.nav-links-container {
  display: flex;
  align-items: center;
}

.nav-links {
  color: #fff;
  font-size: 1rem;
  text-decoration: none;
  margin-right: 20px;
}

.nav-links:hover {
  color: #f1c40f;
}

.shop-button {
  color: #fff;
  font-size: 1rem;
  text-decoration: none;
  padding: 10px 20px;
  border: 2px solid #fff;
  border-radius: 5px;
  background-color: transparent; /* Same color as navbar */
  margin-left: 20px; /* Space between HOME and SHOP */
  display: flex;
  align-items: center;
  justify-content: center;
}

.shop-button:hover {
  background-color: #fff;
  color: #242424; /* Text color when hovered */
}

.dropdown-menu {
  position: absolute;
  top: 80px;
  left: 20px;
  background-color: #242222;
  padding: 1rem;
  border-radius: 5px;
}

.dropdown-list {
  list-style-type: none;
  padding: 0;
}

.dropdown-list li {
  padding: 10px 0;
}

.dropdown-list li a {
  color: #fff;
  text-decoration: none;
}

.dropdown-list li a:hover {
  color: #f1c40f;
}
