@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

.scrollable-section {
  overflow: visible;
  height: auto;
}


.hero-background {
  position: relative;
  background: url("/src/images/img-2.jpg") center center/cover no-repeat;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #f0bd33;
  z-index: 1;
  margin-bottom: 40px;

  /* Apply animation for the background */
  animation: slideInFromRight 1.5s ease-out forwards;
}

.hero-background h1 {
  color: white; /* Makes only the "Sei" white */
  position: absolute;
  top: 50px;
  left: 50px;
  font-size: 100px;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.7);
  margin: 0;
  padding: 0;

  /* Apply animation for the text */
  animation: slideInFromRight 1.5s ease-out forwards;
  animation-delay: 0.5s; /* Optional: Delay to stagger the text animation */
}


.hero-background p {
  font-size: 32px;
  margin-top: 20px;
}

.hero-btns {
  margin-top: 32px;
}

.scrollable-container {
  height: 100vh;
  width: 100%;
  overflow-y: auto;
  background-color: #f0f0f0;
  box-sizing: border-box;
  min-height: 100vh;
}

.scrollable-container h1, .scrollable-container h2, .scrollable-container h3 {
  color: #333;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  padding-top: 20px;
}

.rectangular-section {
  /* background: url("/src/images/scrollable-rectangle-image.jpg") center center/cover no-repeat;  */
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f9f9f9;
  padding: 40px;
  margin: 40px 0;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
  max-width: 1600px;
  width: 100%;
  margin: 0 auto;
  margin-top: 40px;
}

.rectangular-section p {
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-size: 1.5em;
}

.rectangular-section h2 {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 2em;
}

.text-content {
  flex: 1;
  max-width: 40%;
  text-align: left;
  padding-right: 40px;
}

.image-content {
  flex: 1;
  max-width: 60%;
}

.image-content img {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 10px;
}

.content {
  max-width: 800px;
  margin: 0 auto;
  flex-direction: column;
  justify-content: left;
  text-align: center;
}

.content > h1 {
  font-size: 2em;
  text-align: center;
  text-decoration: underline;
}

.content > h3 {
  font-size: 1.2em;
  line-height: 1.6;
  margin-top: 20px;
  margin-bottom: 20px;
}

.content > h2 {
  margin-top: 20px;
}

.content a {
  text-decoration: none;
  color: inherit;
  transition: color 0.3s;
}

.korean-skincare-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 40px 20px; /* Increased padding for better spacing */
}

.korean-skincare-container h1 {
  font-size: 2.5em;
  color: #000;
  margin-bottom: 20px;
}

.ks-explore {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  padding: 40px; /* Space inside the container */
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 60%; /* Adjusted to ensure it’s wide enough for image */
  max-width: 700px; /* Maximum width to control size */
  height: auto; /* Allow height to adjust based on content */
  margin-bottom: 40px; /* Add space between sections to avoid overlap */
}

.ks-explore-content {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center items horizontally */
}

.ks-explore img {
  max-width: 80%;
  margin-bottom: 20px; /* Space between image and text */
}

.ks-explore p {
  font-size: 1.5em;
  text-align: center; /* Ensure text is centered */
  margin-bottom: 20px;
}

.ks-explore button {
  background-color: #ffffff;
  color: rgb(0, 0, 0);
  border: 2px solid #000;
  border-radius: 5px;
  cursor: pointer;
  padding: 15px 30px;
  font-size: 1.2em;
  margin-top: 10px; /* Space between text and button */
  text-align: center;
}

.ks-explore button:hover {
  background-color: #c3cad1;
}

.ks-explore img {
  max-width: 80%; /* Adjust image size */
  margin-bottom: 20px; /* Add space between image and text */
}

.view-now-link {
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  text-decoration: none;
  color: inherit;
}

.who-are-we {
  margin-top: 20px;
}

.scrollable-container h1, .scrollable-container h2, .scrollable-container h3 {
  color: #333;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  padding-top: 20px;
}

.view-now-button {
  padding: 10px;
}

.image-container {
  width: 100%;              /* Make the image container fill the width */
  display: flex;
  justify-content: center;   /* Center the image horizontally */
  align-items: center;       /* Center the image vertically */
  position: relative;
  padding-top: 40px;
}

.image-container img {
  max-width: 110%;
  max-height: 95%; /* Keep the height at 100% of the container */
  object-fit: contain; /* Ensure the aspect ratio is maintained */
  border-radius: 10px; /* Add rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add a subtle box-shadow */
  transition: transform 0.3s ease-in-out;
}

.most-popular-text {
  position: absolute;
  top: 80px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
  font-size: 1.5em;
  font-weight: bold;
  color: #333333;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);  
  padding: 5px 10px;
  border-radius: 5px;
  text-align: center;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 0;
  width: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow: hidden;
  padding-top: 100px;
}

.grid-item {
  position: relative;
  overflow: hidden;
}

.grid-item img {
  width: 100%;
  height: auto;
  object-fit: cover;
  transition: transform 0.3s ease-in-out;
}

.image-overlay {
  position: relative;
  width: 100%;
  height: 100%;
}

.image-overlay img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.exfoliants-image-container:hover, .image-container:hover img, .image-overlay:hover img {
  transform: scale(1.05);
}

.overlay-text {
  position: absolute;
  color: #333333; /* Change to white or contrasting color */
  font-size: 2em;
  font-weight: bold;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
  padding: 10px;
  border-radius: 5px;
  left: 50%;
  bottom: 10px; /* Adjust to position text correctly */
  transform: translateX(-50%);
  font-family: 'Roboto', sans-serif;
}

.exfoliants-container {
  display: flex;
  justify-content: center; /* Center horizontally */
  width: 100%;
}

.exfoliants-image-container {
  width: 50vw; /* Make the container width 50% of viewport width */
  display: flex;
  justify-content: center; /* Center the image horizontally */
  align-items: center; /* Center the image vertically */
  position: relative;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add a subtle box-shadow */
  border-radius: 10px; /* Match border-radius of other sections */
  transition: transform 0.3s ease-in-out;
}

.exfoliants-image-container img {
  width: 100%; /* Make the image fill the container */
  height: auto; /* Maintain aspect ratio */
  border-radius: 10px; /* Match border-radius of container */
  object-fit: cover; /* Ensure aspect ratio is maintained */
}

.exfoliants-text {
  position: absolute;
  color: #333333;
  font-size: 2em; /* Increase font size for better visibility */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
  padding: 10px;
  border-radius: 5px;
  left: 50%;
  bottom: 10px; /* Adjust to position text correctly */
  transform: translateX(-50%);
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.who-are-we-container {
  width: 80%; /* Adjust width if needed */
  max-width: 1200px; /* Set a max-width to prevent it from getting too wide */
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
  margin: 50px auto; /* Center the container and add space below */
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 20px; /* Space between text and image */
}

.who-are-we-text h1 {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 2em;
}

.who-are-we-text p {
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-size: 1.5em;
}

.who-are-we-text {
  flex: 1;
  max-width: 40%;
  text-align: left;
  padding-right: 40px;
}


.who-are-we-image {
  flex: 1; /* Allow the image section to take up available space */
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 20px;
}

.who-are-we-image img {
  width: 100%;
  max-width: 100%; /* Ensure image does not exceed container width */
  height: auto;
  border-radius: 10px;
}

@keyframes slideInFromRight {
  0% {
    transform: translateX(100%); /* Start off-screen to the right */
    opacity: 0;
  }
  100% {
    transform: translateX(0); /* End at the default position */
    opacity: 1;
  }
}