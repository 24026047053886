/* Container for the entire cleansers section */
.cleansers-container {
    padding: 20px;
    background-color: #ffffff;
}

/* Each category section */
.category-section {
    text-align: center;
    margin-bottom: 60px;
}

/* Category titles */
.category-section h1 {
    font-size: 32px;
    margin-bottom: 30px;
    color: #333333;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
}

/* Products container */
.products {
    display: flex;
    flex-wrap: nowrap; /* Ensure no wrapping so horizontal scroll works */
    overflow-x: auto; /* Enable horizontal scrolling */
    gap: 40px;
    padding: 10px;
    justify-content: flex-start;
    width: 100%;
}

/* Hide scrollbar for Webkit browsers */
.products::-webkit-scrollbar {
    display: none;
}

/* Individual product card */
.product {
    text-align: center;
    width: 300px;
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    background-color: #fefefe;
    transition: transform 0.3s, box-shadow 0.3s;
}

.product:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
}

/* Product images */
.product img {
    width: 100%;
    height: 300px;
    object-fit: cover;
    border-radius: 8px;
    margin-bottom: 20px;
}

/* Product titles */
.product h2 {
    font-size: 24px;
    color: #222222;
    margin: 10px 0;
}

.product h2 a {
    color: inherit;
    text-decoration: none;
}

.product p {
    color: #333;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
}

.product h2 a:hover {
    color: #007BFF;
    text-decoration: underline;
}

/* Rating stars and number */
.rating {
    margin-top: 10px;
    font-size: 24px;
    color: #FFD700;
    display: flex;
    align-items: center;
    justify-content: center;
}

.rating span {
    margin-left: 8px;
    font-size: 18px;
    color: #555555;
}

/* Product descriptions */
.description {
    margin-top: 15px;
    font-size: 16px;
    color: #555555;
    text-align: center;
    line-height: 1.5;
}

/* Mobile styles */
@media screen and (max-width: 768px) {
    .products {
        flex-wrap: wrap; /* Change to wrap so items stack on small screens */
        justify-content: center;
        gap: 30px;
    }

    .product {
        width: 90%; /* Adjust width for mobile */
    }

    .product img {
        height: 250px; /* Slightly smaller image height for mobile */
    }

    .category-section h1 {
        font-size: 28px;
    }

    .product h2 {
        font-size: 20px;
    }

    .rating {
        font-size: 20px;
    }

    .description {
        font-size: 14px;
    }
}
